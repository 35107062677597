<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="flag" />
    </keep-alive>
    <router-view v-if="!flag" />
    <Tabbar v-show="$route.meta.istabPage"/>
  </div>
</template>

<script>
import Tabbar from '@cpt/Tabbar.vue'

export default {
  data() {
    return {
      flag: false
    }
  },
  components: {
    Tabbar
  },
  created () {
    if(this.$Cookies.get('setUserInfo')) {
      const cookie_token = this.$Cookies.get('setToken');
      const cookie_user_info = JSON.parse(this.$Cookies.get('setUserInfo'));
      this.$store.commit('setToken', cookie_token); // 存储token
      this.$store.commit('setUserInfo', cookie_user_info); // 存储用户信息
    }
  },
  watch: {
    $route(to) {
      if(to.meta.hasOwnProperty('keepAlive')) {this.flag = to.meta.keepAlive}
      else {this.flag = false}
    },
  }
}
</script>

<style lang="less">

</style>
