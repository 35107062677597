import axios from 'axios'
import vm from '../main'

const env = process.env.NODE_ENV; // 获取运行环境

const instance = axios.create({
  // baseURL: 'http://192.168.31.214',
  baseURL: env == 'test' ? "https://ausapitest.meifanco.cn" : 'https://api.meifan.com.au', // 接口请求路径
  timeout: 24 * 3600 * 1000
})
instance.interceptors.request.use(config => {
  const token = vm.$Cookies.get().setToken;
  config.headers.common['Authorization'] = `Bearer ${token}`;
  return config
})

instance.interceptors.response.use(res => res.data)

function http(method, path, params={}, config={}) {
  return new Promise((resolve, reject) => {
    const req = method === 'get' ? instance.get(path, {params}) : instance.post(path, params, config);
    req.then(res => _handleSuccess(res, resolve, reject))
    .catch(error => _handleError())
  })
}

function _handleSuccess(res, resolve, reject) { // 请求成功处理
  switch(res.code) {
    case 0:
      resolve(res)
    break;
    case -100:
      _handleRedirectLogin(100);
    break;
    case -101:
      vm.$store.commit('setToken', '') // 清除token - 防止token存在 重定向登录页后又重新跳回首页
      _handleRedirectLogin(101);
    break;
    default:
      vm.$toast(res.msg)
      return reject(res.msg)
  }
}

function _handleError() { // 请求失败处理
  vm.$toast.clear();
  vm.$toast('服务器错误')
}

function _handleRedirectLogin(code) { // 登录重定向处理
  // 当前数组中的路由名称，重定向登录页后可以返回，其他不在数组中的路由则会重定向登录页后点击返回又被重定向到登录页的循环中，效果为点击返回无效一直在登录页
  const route_name = ['Cart', 'Mine', 'OrderCheck']
  if(route_name.includes(this.$route.name)) {
    this.$router.replace({name: 'Login', query:{code}})
  } else {
    this.$router.push({name: 'Login', query:{code}})
  }
}

export const get = (...agr) => http('get', ...agr);
export const post = (...agr) => http('post', ...agr);