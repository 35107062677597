export default {
  setToken(state, token = '') {
    state.token = token
  },
  setUserInfo(state, data = null) {
    state.userInfo = data
  },
  setList(state, data){
    state.ordLists = data
  },
  setDelayJump(state, callback) {
    state.tempId = setTimeout(() => {callback&&callback()}, 2000)
  },
  setArea(state, data) {
    state.detaArea = data
  },
  detaArea(state, data){
    state.area = data
  },
  setOrder(state, data){
    state.orderList = data
  },
  setCancelReason(state, data) {
    state.cancelReason = data
  },
  addToCart(state, params) {
    state.cartList = params
  },
  setCheckAdr(state, data = null) {
    state.selectCheckAdr = data
  },
  setOrderCheck(state, params) {
    state.orderCheck = params
  },
  clearOrderAdr(state, key) {
    if(state.selectOrderAdr.hasOwnProperty(key)) {delete state.selectOrderAdr[key]}
  },
  setRefundReason(state, data) {
    state.refundReason = data
  },
  setLangType(state, data) {
    state.lang_type = data
  }
}
