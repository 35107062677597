import {get, post} from './http'
export const getIndex = () => get('/v1/index');  //首页
// export const getIndex = () => get('/');  //首页

export const getCate = () => get('/v1/category/index');  //分类

export const getGoodsList = params => get('/v1/goods/index', params);  //商品列表接口
export const getGoodsInfo = (id, params) => get(`/v1/goods/${id}`, params);  //商品详情

export const getOrder = params => get('/order', params)
export const email_login = params => post('/login/emailLogin', params)  //登录
export const log_out = params => get('/v1/user/logout', params)  //退出登录
export const getUserInfo = id  => get('/v1/user_center')  //个人中心
export const editUser = params  => post(`/v1/user/edit`, params)  //用户编辑
export const uploadImage = (params, config) => post('/upload/image', params, config) // 上传图片

export const sms_code = params => post('/register/send_code', params)  //发送验证码
export const updata_pwd = params => post('/register/rePwd', params)  //修改密码
export const register = params => post('/register/register', params)  //注册账号

export const uploadIdCard = params => post('/v1/user_identity/upload/', params)  //上传身份证
export const editingIdCard = (id, params) => post(`v1/user_identity/edit/${id}`, params);  //编辑用户地址身份证信息

export const addRessList = params => get('v1/user_address', params)  // 用户地址列表
export const addAddress = params => post('v1/user_address/add', params)  // 添加收货地址
export const editAddress = (id,params) => post(`v1/user_address/edit/${id}`, params)  // 编辑收货地址
export const delAear = id  => get(`/v1/user_address/del/${id}`)  //删除地址

export const getArea = params => get('v1/area', params)  // 地区列表
export const detailedArea = (area_pcode, params) => get(`v1/area/${area_pcode}`, params)  // 详细地区

export const orderCheck = params => post('/v1/order/check', params)  // 确认订单
export const orderAdd = params => post('/v1/order/add', params)  // 确认订单
export const orderList = params => get('v1/order', params)  // 订单列表
export const orderDetails = (id, params) => get(`/v1/order/${id}`, params);  //订单详情
export const cancelOrder = params => post('/v1/order/cancel', params)  // 取消订单
export const cancellation = params => get('/v1/order_cancel/reason', params)  // 取消订单原因
export const pay = params => post('/v1/order/pay', params)  // 订单支付
export const getRefundReason = () => get('/v1/order_refund/reason')  // 订单退款原因
export const applyRefund = prams => post('/v1/order/apply_refund', prams)  // 申请退款
export const delOrder = id => get(`/v1/order/del/${id}`)  // 删除订单
export const receivedOrder = params => post(`/v1/order/received`, params)  // 确认收货

export const applyService = id => get(`/v1/after_sale/apply?order_id=${id}`, {}) // 申请售后
export const addService = params => post(`v1/after_sale/add`, params) // 提交售后
export const serviceInfo = id => get(`/v1/after_sale/info?id=${id}`, {}) // 售后详情
export const cancelService = id => post(`/v1/after_sale/cancel?after_sale_id=${id}`) // 撤销售后
export const afterSale = params => get(`/v1/after_sale/lists`, params)  // 售后列表

export const getKeywords = () => get('/v1/keywords', {})  // 关键词
export const getBalance = () => get('/v1/department')  // 充值余额
export const getConsume = params => get('/v1/user_consume', params)  // 消费记录