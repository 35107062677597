import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import language from '@utils/language'

Vue.use(Vuex)

const state = {
  tempId: null, // 加载错误返回上级页面定时器，防止没有跳转用户已经点击返回
  area: null, // 省市区数据
  detaArea: null, //详细数据
  refunds: [ // 申请售后-退款数据
    {name: '余额', type: 'balance', value: 1},
    {name: '线下转账', type: 'offline', value: 2}
  ],
  cancelReason: null, // 订单取消原因
  token: '',
  cartList: [],  //购物车
  orderList: null, //订单数据
  ordLists: null,
  selectCheckAdr: null, // 订单详情选中收货地址
  refundReason: null, // 申请退款原因
  userInfo: null,  //用户信息
  orderCheck: null,  //选择下单
  language,
  lang_type: 'zh_TW'
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {}
})
