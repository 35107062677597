import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import VueClipboard from 'vue-clipboard2'
import * as filters from '@utils/filters'
import * as utils from '@utils/utils'
import Vant, {Lazyload} from 'vant';
import {ScrollNav} from 'cube-ui';
import 'vant/lib/index.css';
import '@icon/iconfont.css';
import '@icon/iconfont.js'
import './assets/less/vant.less'

Vue.config.productionTip = false;

// 注册全局过滤器
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))

Vue.use({install: (Vue) => Vue.prototype['$Cookies'] = Cookies})
Vue.use({install: (Vue, opt) => Object.keys(utils).forEach(key => Vue.prototype[`$${key}`] = utils[key])})
Vue
.use(VueClipboard)
.use(Vant)
.use(Lazyload, {lazyComponent: true})
.use(ScrollNav)

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

