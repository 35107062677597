export default {
  zh_CN: {
    // 首页
    l_1: '跨境好服务',
    l_2: '臻品永不停',
    l_3: '登录',
    l_4: '推荐',
    //分类
    l_5: '搜索商品',
    //购物车
    l_6: '购物车',
    l_7: '完成',
    l_8: '管理',
    l_9: '共',
    l_10: '件商品',
    l_11: '哎呀，购物车竟然是空的',
    l_12: '去逛逛',
    l_13: '全选',
    l_14: '删除',
    l_15: '合计',
    l_16: '去结算',
    l_17: '登录后可同步购物车中的商品',
    l_18: '请选择商品',
    l_19: '请选择要删除的商品哦',
    l_20: '是否删除这',
    l_21: '件商品？',
    l_22: '我再想想',
    //我的
    l_23: '未登录',
    l_24: '全部订单',
    l_25: '待付款',
    l_26: '待收货',
    l_27: '退货/售后',
    l_28: '地址管理',
    l_29: '余额',
    l_30: '充值余额',
    l_31: '消费记录',
    //OrderListTemp
    l_32: '共计商品',
    l_33: '件',
    l_34: '交易取消',
    l_35: '删除订单',
    l_36: '取消订单',
    l_37: '重新支付',
    l_38: '设置地址',
    l_39: '待出库',
    l_40: '自提',
    l_41: '申请退款',
    l_42: '已发货',
    l_43: '已签收',
    l_44: '待发货',
    l_45: '部分发货',
    l_46: '交易完成',
    l_47: '申请售后',
    l_48: '售后中',
    l_49: '售后进度',
    l_50: '退款处理中',
    l_51: '已退款',
    l_52: '售后完成',
    l_53: '再去逛逛',
    l_54: '确认收货',
    l_55: '确定要删除吗，删除之后找不回哟~',
    l_56: '删除成功',
    l_57: '是否确认收货？',
    l_58: '收货成功',
    l_59: '取消收货',
    // ServiceCard
    l_60: '实付：',
    l_61: '待处理',
    l_62: '处理中',
    l_63: '售后拒绝',
    l_64: '已撤销',
    // Tabbar
    l_65: '首页',
    l_66: '分类',
    l_67: '我的',
    // GoodsInfo
    l_68: '预计税费：',
    l_69: '预计税费：商家承担',
    l_70: '服务政策',
    l_71: '正品保障，假一罚十',
    l_72: '消费者告知书',
    l_73: '查看全部',
    l_74: '用户须知 · 跨境答疑 · 价格说明',
    l_75: '商品详情',
    l_76: '加入购物车',
    l_77: '立即购买',
    l_78: '数量：',
    l_79: '商品规格',
    l_80: '商品数量',
    l_81: '消费者告知书',
    l_82: '尊敬的用户：为了您更好地选择境外物品，请您在下单前务必认真详细阅读并完全理解本告知书相关内容，对自身风险作出客观判断，完全同意本告知书内容后再下单。',
    l_83: '2、您购买的所有境外物品适用的品质、健康、安全、卫生、环保、标示等项目标准可能与我国质量安全标准不同，由此可能产生的危害或损失以及法律责任或其他风险，将由您个人承担。',
    l_84: '3、您在本站购买的境外物品可能无中文标签或说明书，请在下单前认真阅读平台提供的物品信息。',
    l_85: '4、建议您对境外物品有一定了解并评估是否适合自己使用后再下单，感谢您的配合。',
    l_86: '已选：',
    l_87: '确定',
    l_88: '请选择商品规格',
    l_89: '添加成功',
    l_90: '1、您通过本网站购买的所有境外物品等同于境外购买。',
    // ConfirmPwd
    l_91: '确认密码',
    l_92: '密码',
    l_93: '请输入您的密码',
    l_94: '重新输入密码',
    l_95: '确定修改',
    l_96: '验证码过期',
    l_97: '修改成功',
    l_98: '密码不同，请重新输入',
    l_99: '密码不能为空',
    l_99_1: '修改',
    l_99_2: '返回',
    // ForgetPwd
    l_100: '忘记密码',
    l_101: '邮箱',
    l_102: '请输入您的邮箱',
    l_103: '验证',
    l_104: '请输入邮箱验证码',
    l_105: 's后重新发',
    l_106: '获取验证码',
    l_107: '邮箱不能为空',
    l_108: '验证码不能为空',
    // Login
    l_109: '请填写邮箱',
    l_110: '请输入你的密码',
    l_111: '请填写密码',
    l_112: '没有账号？',
    l_113: '注册',
    l_114: '登录成功',
    // Register
    l_115: '手机号码',
    l_116: '请输入您的手机号',
    l_117: '发送成功',
    l_118: '注册成功',
    l_119: '请输入相同的密码',
    l_120: '手机号不能为空',
    // MineAddress
    l_121: '姓名',
    l_122: '收货人姓名',
    l_123: '请填写用户名',
    l_124: '电话',
    l_125: '收货人手机号',
    l_126: '请填写手机号',
    l_127: '身份证号',
    l_128: '收货人身份证号',
    l_129: '请填写身份证',
    l_130: '地区',
    l_131: '选择省 / 市 / 区',
    l_132: '请选择省市区',
    l_133: '详细地址',
    l_134: '街道门牌、楼层房间号等信息',
    l_135: '请填写详细地址',
    l_136: '设为默认地址',
    l_137: '确认',
    l_138: '删除地址',
    l_139: '取消',
    l_140: '默认',
    l_141: '编辑',
    l_142: '国内',
    l_143: '海外',
    l_144: '新增收货地址',
    l_145: '中国',
    l_146: '国际',
    l_147: '修改需重新上传身份证',
    l_148: '算了',
    l_149: '去上传',
    l_150: '收货人姓名不能为空',
    l_151: '详细地址不能为空',
    l_152: '请选择地址',
    l_153: '编辑成功',
    l_154: '编辑失败',
    l_155: '添加失败',
    l_156: '确认删除该地址吗？',
    // MineCollect
    l_157: '商品收藏',
    l_158: '品牌收藏',
    l_159: '人付款',
    l_160: '名称：',
    l_161: '描述：',
    l_162: '取消成功',
    // MineConsume
    l_163: '备注：',
    l_164: '暂无消费记录',
    // MineIdCard
    l_165: '请拍摄并上传需要清关人员身份证照片',
    l_166: '身份证信息',
    l_167: '身份证姓名(自动识别)',
    l_168: '身份证',
    l_169: '身份证号码(自动识别)',
    l_170: '关联地址',
    l_171: '选择地址',
    l_172: '身份证上传说明',
    l_173: '根据中国海关规定，为防止变相走私，证明包裹内物品确实为个人自用，个人包裹办理海关入境清关手续需要提交收件人身份证明。',
    l_174: '海关相关规定请参考《中华人民共和国海关对进出境快件监管办法》第二十二条，或致电海关咨询：12360',
    l_175: '拍摄身份证要求',
    l_176: '大陆公民持有的本人有效二代身份证',
    l_177: '拍摄时确保身份证',
    l_178: '边框完整，字体清晰，亮度均匀;',
    l_179: '标准',
    l_180: '边框缺失',
    l_181: '证件模糊',
    l_182: '闪光强烈',
    l_183: '确定上传',
    l_184: '请选择关联地址',
    l_185: '识别成功',
    l_186: '识别失败',
    l_187: '请上传完整的身份证',
    l_188: '上传成功',
    // MineRecharge
    l_189: '如果需要充值账户余额，请联系客服手机号码：',
    // OrderCheck
    l_190: '手动添加收货地址',
    l_191: '实际发货',
    l_192: '发货方式',
    l_193: '请输入收货人身份证号',
    l_194: '订单备注',
    l_195: '选填，添加订单备注',
    l_196: '运费',
    l_197: '实付金额：',
    l_198: '提交订单',
    l_199: '支付成功',
    // OrderInfo
    l_200: '等待买家付款',
    l_201: '逾期未支付，订单自动取消',
    l_202: '感谢您的购买',
    l_203: '等待发货',
    l_204: '等待出库',
    l_205: '售后申请中',
    l_206: '退款成功',
    l_207: '交易已取消',
    l_208: '应付金额',
    l_209: '实付金额',
    l_210: '订单编号：',
    l_211: '复制',
    l_212: '支付方式：',
    l_213: '下单时间：',
    l_214: '温馨提示',
    l_215: '订单一经取消，无法恢复',
    l_216: '订单所用红包，优惠券支持退回',
    l_217: '若您支付未成功，请及时联系客服',
    l_218: '请选择取消订单的原因：',
    l_219: '请选择退款的原因：',
    l_220: '复制成功',
    l_221: '复制失败',
    l_222: '请选择一个原因',
    l_223: '取消失败',
    l_224: '按原支付方式支付',
    l_225: '支付取消',
    l_226: '申请成功',
    l_227: '申请失败',
    // OrderList
    l_228: '你没有相关订单，快去商场下单吧！',
    // Search
    l_229: '搜索',
    l_230: '搜索历史',
    l_231: '热门搜索',
    l_232: '请输入搜索内容',
    l_233: '是否删除全部历史搜索',
    // SearchInfo
    l_234: '综合',
    l_235: '销量',
    l_236: '价格',
    l_237: '没有相关数据哦',
    // ServiceApply
    l_238: '售后类型',
    l_239: '货物状态',
    l_240: '申请原因',
    l_241: '申请说明',
    l_242: '请输入申请说明',
    l_243: '订单金额',
    l_244: '请选择售后商品',
    l_245: '运费金额',
    l_246: '请输入运费金额',
    l_247: '售后金额',
    l_248: '请输入售后金额',
    l_249: '上传凭证',
    l_250: '联系方式',
    l_251: '请输入手机号',
    l_252: '退款方式',
    l_253: '请输入正确的手机号',
    l_254: '手机号码不能为空',
    l_255: '收款账号不能为空',
    l_256: '售后金额不能大于订单金额',
    l_257: '运费金额不能大于订单金额',
    l_258: '扣款金额不能大于订单金额',
    l_259: '上传中',
    l_260: '上传失败',
    l_261: '请勾选需要售后的商品',
    l_262: '请选择售后类型',
    l_263: '请选择货物状态',
    l_264: '请选择申请原因',
    l_265: '提交成功',
    // Service
    l_266: '全部',
    // ServiceInfo
    l_267: '申请已受理，售后专员正在处理中',
    l_268: '退款成功，请耐心等待回款',
    l_269: '尊敬的客户，感谢您前来购物。我们会为您打造优质的购物体验',
    l_270: '退款金额',
    l_271: '商家拒绝您的申请，请及时处理',
    l_272: '尊敬的用户，您的售后已被拒绝。如果您有其他退款诉求请联系客服',
    l_273: '您已主动撤销此次售后申请',
    l_274: '您需要将商品寄回',
    l_275: '地   址：江西 · 南昌 · 红谷滩 · 红角洲学府大道899号',
    l_276: '手   机：13916124423',
    l_277: '收件人：江西美翻科技有限公司',
    l_278: '物流信息已提交，售后专员处理中',
    l_279: '申请已提交，请等待售后专员处理',
    l_280: '申请通过：款项将原路退还至您的账户',
    l_281: '处理时长：专员会在一周之内处理完成',
    l_282: '撤销申请',
    l_283: '售后金额：',
    l_284: '售后类型：',
    l_285: '货物状态：',
    l_286: '申请原因：',
    l_287: '退款编号：',
    l_288: '申请时间：',
    l_289: '确定要撤销吗，撤销之后不能再次哟~',
    l_290: '撤销',
    l_291: '撤销成功',
    l_292: '物流单号不能为空',
    l_293: '请选择物流公司',
    // UpdateInfo
    l_294: '用户名',
    l_295: '昵称',
    l_296: '描述',
    l_297: '原密码',
    l_298: '新密码',
    l_299: '提交',
    l_300: '更改成功',
    l_301: '取消修改',
    l_302: '用户名不能为空',
    l_303: '原密码不能为空',
    // UserInfo
    l_304: '暂无昵称',
    l_305: '主人有点懒，还未添加简介',
    l_306: '昵称',
    l_307: '手机号',
    l_308: '修改用户信息',
    l_309: '退出当前账号',
    l_310: '简介',
    l_311: '退出成功',
    // 补充
    l_312: '查看详情',
    l_313: '取消删除',
    l_314: '刷新成功',
    l_315: '取消撤销',
    l_316: '原价：',
    l_317: 'VIP优惠',
  },
  zh_TW: {
    // 首页
    l_1: '跨境好服務',
    l_2: '臻品永不停',
    l_3: '登錄',
    l_4: '推薦',
    //分类
    l_5: '搜索商品',
    //购物车
    l_6: '購物車',
    l_7: '完成',
    l_8: '管理',
    l_9: '共',
    l_10: '件商品',
    l_11: '哎呀，購物車竟然是空的',
    l_12: '去逛逛',
    l_13: '全選',
    l_14: '刪除',
    l_15: '合計',
    l_16: '去結算',
    l_17: '登錄後可同步購物車中的商品',
    l_18: '請選擇商品',
    l_19: '請選擇要刪除的商品哦',
    l_20: '是否刪除這',
    l_21: '件商品？',
    l_22: '我再想想',
    //我的
    l_23: '未登錄',
    l_24: '全部訂單',
    l_25: '待付款',
    l_26: '待收貨',
    l_27: '退貨/售後',
    l_28: '地址管理',
    l_29: '余額',
    l_30: '充值余額',
    l_31: '消費記錄',
    //OrderListTemp
    l_32: '共計商品',
    l_33: '件',
    l_34: '交易取消',
    l_35: '刪除訂單',
    l_36: '取消訂單',
    l_37: '重新支付',
    l_38: '設置地址',
    l_39: '待出庫',
    l_40: '自提',
    l_41: '申請退款',
    l_42: '已發貨',
    l_43: '已簽收',
    l_44: '待發貨',
    l_45: '部分發貨',
    l_46: '交易完成',
    l_47: '申請售後',
    l_48: '售後中',
    l_49: '售後進度',
    l_50: '退款處理中',
    l_51: '已退款',
    l_52: '售後完成',
    l_53: '再去逛逛',
    l_54: '確認收貨',
    l_55: '確定要刪除嗎，刪除之後找不回喲~',
    l_56: '刪除成功',
    l_57: '是否確認收貨？',
    l_58: '收貨成功',
    l_59: '取消收貨',
    // ServiceCard
    l_60: '實付：',
    l_61: '待處理',
    l_62: '處理中',
    l_63: '售後拒絕',
    l_64: '已撤銷',
    // Tabbar
    l_65: '首頁',
    l_66: '分類',
    l_67: '我的',
    // GoodsInfo
    l_68: '預計稅費：',
    l_69: '預計稅費：商家承擔',
    l_70: '服務政策',
    l_71: '正品保障，假一罰十',
    l_72: '消費者告知書',
    l_73: '查看全部',
    l_74: '用戶須知 · 跨境答疑 · 價格說明',
    l_75: '商品詳情',
    l_76: '加入購物車',
    l_77: '立即購買',
    l_78: '數量：',
    l_79: '商品規格',
    l_80: '商品數量',
    l_81: '消費者告知書',
    l_82: '尊敬的用戶：為了您更好地選擇境外物品，請您在下單前務必認真詳細閱讀並完全理解本告知書相關內容，對自身風險作出客觀判斷，完全同意本告知書內容後再下單。',
    l_83: '2、您購買的所有境外物品適用的品質、健康、安全、衛生、環保、標示等項目標準可能與我國質量安全標準不同，由此可能產生的危害或損失以及法律責任或其他風險，將由您個人承擔。',
    l_84: '3、您在本站購買的境外物品可能無中文標簽或說明書，請在下單前認真閱讀平臺提供的物品信息。',
    l_85: '4、建議您對境外物品有一定了解並評估是否適合自己使用後再下單，感謝您的配合。',
    l_86: '已選：',
    l_87: '確定',
    l_88: '請選擇商品規格',
    l_89: '添加成功',
    l_90: '1、您通過本網站購買的所有境外物品等同於境外購買。',
    // ConfirmPwd
    l_91: '確認密碼',
    l_92: '密碼',
    l_93: '請輸入您的密碼',
    l_94: '重新輸入密碼',
    l_95: '確定修改',
    l_96: '驗證碼過期',
    l_97: '修改成功',
    l_98: '密碼不同，請重新輸入',
    l_99: '密碼不能為空',
    l_99_1: '修改',
    l_99_2: '返回',
    // ForgetPwd
    l_100: '忘記密碼',
    l_101: '郵箱',
    l_102: '請輸入您的郵箱',
    l_103: '驗證',
    l_104: '請輸入郵箱驗證碼',
    l_105: 's後重新發',
    l_106: '獲取驗證碼',
    l_107: '郵箱不能為空',
    l_108: '驗證碼不能為空',
    // Login
    l_109: '請填寫郵箱',
    l_110: '請輸入你的密碼',
    l_111: '請填寫密碼',
    l_112: '沒有賬號？',
    l_113: '註冊',
    l_114: '登錄成功',
    // Register
    l_115: '手機號碼',
    l_116: '請輸入您的手機號',
    l_117: '發送成功',
    l_118: '註冊成功',
    l_119: '請輸入相同的密碼',
    l_120: '手機號不能為空',
    // MineAddress
    l_121: '姓名',
    l_122: '收貨人姓名',
    l_123: '請填寫用戶名',
    l_124: '電話',
    l_125: '收貨人手機號',
    l_126: '請填寫手機號',
    l_127: '身份證號',
    l_128: '收貨人身份證號',
    l_129: '請填寫身份證',
    l_130: '地區',
    l_131: '選擇省 / 市 / 區',
    l_132: '請選擇省市區',
    l_133: '詳細地址',
    l_134: '街道門牌、樓層房間號等信息',
    l_135: '請填寫詳細地址',
    l_136: '設為默認地址',
    l_137: '確認',
    l_138: '刪除地址',
    l_139: '取消',
    l_140: '默認',
    l_141: '編輯',
    l_142: '國內',
    l_143: '海外',
    l_144: '新增收貨地址',
    l_145: '中國',
    l_146: '國際',
    l_147: '修改需重新上傳身份證',
    l_148: '算了',
    l_149: '去上傳',
    l_150: '收貨人姓名不能為空',
    l_151: '詳細地址不能為空',
    l_152: '請選擇地址',
    l_153: '編輯成功',
    l_154: '編輯失敗',
    l_155: '添加失敗',
    l_156: '確認刪除該地址嗎？',
    // MineCollect
    l_157: '商品收藏',
    l_158: '品牌收藏',
    l_159: '人付款',
    l_160: '名稱：',
    l_161: '描述：',
    l_162: '取消成功',
    // MineConsume
    l_163: '備註：',
    l_164: '暫無消費記錄',
    // MineIdCard
    l_165: '請拍攝並上傳需要清關人員身份證照片',
    l_166: '身份證信息',
    l_167: '身份證姓名(自動識別)',
    l_168: '身份證',
    l_169: '身份證號碼(自動識別)',
    l_170: '關聯地址',
    l_171: '選擇地址',
    l_172: '身份證上傳說明',
    l_173: '根據中國海關規定，為防止變相走私，證明包裹內物品確實為個人自用，個人包裹辦理海關入境清關手續需要提交收件人身份證明。',
    l_174: '海關相關規定請參考《中華人民共和國海關對進出境快件監管辦法》第二十二條，或致電海關咨詢：12360',
    l_175: '拍攝身份證要求',
    l_176: '大陸公民持有的本人有效二代身份證',
    l_177: '拍攝時確保身份證',
    l_178: '邊框完整，字體清晰，亮度均勻;',
    l_179: '標準',
    l_180: '邊框缺失',
    l_181: '證件模糊',
    l_182: '閃光強烈',
    l_183: '確定上傳',
    l_184: '請選擇關聯地址',
    l_185: '識別成功',
    l_186: '識別失敗',
    l_187: '請上傳完整的身份證',
    l_188: '上傳成功',
    // MineRecharge
    l_189: '如果需要充值賬戶余額，請聯系客服手機號碼：',
    // OrderCheck
    l_190: '手動添加收貨地址',
    l_191: '實際發貨',
    l_192: '發貨方式',
    l_193: '請輸入收貨人身份證號',
    l_194: '訂單備註',
    l_195: '選填，添加訂單備註',
    l_196: '運費',
    l_197: '實付金額：',
    l_198: '提交訂單',
    l_199: '支付成功',
    // OrderInfo
    l_200: '等待買家付款',
    l_201: '逾期未支付，訂單自動取消',
    l_202: '感謝您的購買',
    l_203: '等待發貨',
    l_204: '等待出庫',
    l_205: '售後申請中',
    l_206: '退款成功',
    l_207: '交易已取消',
    l_208: '應付金額',
    l_209: '實付金額',
    l_210: '訂單編號：',
    l_211: '復制',
    l_212: '支付方式：',
    l_213: '下單時間：',
    l_214: '溫馨提示',
    l_215: '訂單一經取消，無法恢復',
    l_216: '訂單所用紅包，優惠券支持退回',
    l_217: '若您支付未成功，請及時聯系客服',
    l_218: '請選擇取消訂單的原因：',
    l_219: '請選擇退款的原因：',
    l_220: '復制成功',
    l_221: '復制失敗',
    l_222: '請選擇一個原因',
    l_223: '取消失敗',
    l_224: '按原支付方式支付',
    l_225: '支付取消',
    l_226: '申請成功',
    l_227: '申請失敗',
    // OrderList
    l_228: '你沒有相關訂單，快去商場下單吧！',
    // Search
    l_229: '搜索',
    l_230: '搜索歷史',
    l_231: '熱門搜索',
    l_232: '請輸入搜索內容',
    l_233: '是否刪除全部歷史搜索',
    // SearchInfo
    l_234: '綜合',
    l_235: '銷量',
    l_236: '價格',
    l_237: '沒有相關數據哦',
    // ServiceApply
    l_238: '售後類型',
    l_239: '貨物狀態',
    l_240: '申請原因',
    l_241: '申請說明',
    l_242: '請輸入申請說明',
    l_243: '訂單金額',
    l_244: '請選擇售後商品',
    l_245: '運費金額',
    l_246: '請輸入運費金額',
    l_247: '售後金額',
    l_248: '請輸入售後金額',
    l_249: '上傳憑證',
    l_250: '聯系方式',
    l_251: '請輸入手機號',
    l_252: '退款方式',
    l_253: '請輸入正確的手機號',
    l_254: '手機號碼不能為空',
    l_255: '收款賬號不能為空',
    l_256: '售後金額不能大於訂單金額',
    l_257: '運費金額不能大於訂單金額',
    l_258: '扣款金額不能大於訂單金額',
    l_259: '上傳中',
    l_260: '上傳失敗',
    l_261: '請勾選需要售後的商品',
    l_262: '請選擇售後類型',
    l_263: '請選擇貨物狀態',
    l_264: '請選擇申請原因',
    l_265: '提交成功',
    // Service
    l_266: '全部',
    // ServiceInfo
    l_267: '申請已受理，售後專員正在處理中',
    l_268: '退款成功，請耐心等待回款',
    l_269: '尊敬的客戶，感謝您前來購物。我們會為您打造優質的購物體驗',
    l_270: '退款金額',
    l_271: '商家拒絕您的申請，請及時處理',
    l_272: '尊敬的用戶，您的售後已被拒絕。如果您有其他退款訴求請聯系客服',
    l_273: '您已主動撤銷此次售後申請',
    l_274: '您需要將商品寄回',
    l_275: '地   址：江西 · 南昌 · 紅谷灘 · 紅角洲學府大道899號',
    l_276: '手   機：13916124423',
    l_277: '收件人：江西美翻科技有限公司',
    l_278: '物流信息已提交，售後專員處理中',
    l_279: '申請已提交，請等待售後專員處理',
    l_280: '申請通過：款項將原路退還至您的賬戶',
    l_281: '處理時長：專員會在一周之內處理完成',
    l_282: '撤銷申請',
    l_283: '售後金額：',
    l_284: '售後類型：',
    l_285: '貨物狀態：',
    l_286: '申請原因：',
    l_287: '退款編號：',
    l_288: '申請時間：',
    l_289: '確定要撤銷嗎，撤銷之後不能再次喲~',
    l_290: '撤銷',
    l_291: '撤銷成功',
    l_292: '物流單號不能為空',
    l_293: '請選擇物流公司',
    // UpdateInfo
    l_294: '用戶名',
    l_295: '昵稱',
    l_296: '描述',
    l_297: '原密碼',
    l_298: '新密碼',
    l_299: '提交',
    l_300: '更改成功',
    l_301: '取消修改',
    l_302: '用戶名不能為空',
    l_303: '原密碼不能為空',
    // UserInfo
    l_304: '暫無昵稱',
    l_305: '主人有點懶，還未添加簡介',
    l_306: '昵稱',
    l_307: '手機號',
    l_308: '修改用戶信息',
    l_309: '退出當前賬號',
    l_310: '簡介',
    l_311: '退出成功',
    // 补充
    l_312: '查看詳情',
    l_313: '取消刪除',
    l_314: '刷新成功',
    l_315: '取消撤銷',
    l_316: '原價：',
    l_317: 'VIP優惠',
  },
  en: {
    // 首页
    l_1: 'Cross-border Good Service',
    l_2: 'The best never stops',
    l_3: 'login',
    l_4: 'recommend',
    //分类
    l_5: 'Search for goods',
    //购物车
    l_6: 'cart',
    l_7: 'finish',
    l_8: 'administration',
    l_9: '共',
    l_10: '件商品',
    l_11: '哎呀，购物车竟然是空的',
    l_12: '去逛逛',
    l_13: '全选',
    l_14: '删除',
    l_15: '合计',
    l_16: '去结算',
    l_17: '登录后可同步购物车中的商品',
    l_18: '请选择商品',
    l_19: '请选择要删除的商品哦',
    l_20: '是否删除这',
    l_21: '件商品？',
    l_22: '我再想想',
    //我的
    l_23: '未登录',
    l_24: '全部订单',
    l_25: '待付款',
    l_26: '待收货',
    l_27: '退货/售后',
    l_28: '地址管理',
    l_29: '余额',
    l_30: '充值余额',
    l_31: '消费记录',
    //OrderListTemp
    l_32: '共计商品',
    l_33: '件',
    l_34: '交易取消',
    l_35: '删除订单',
    l_36: '取消订单',
    l_37: '重新支付',
    l_38: '设置地址',
    l_39: '待出库',
    l_40: '自提',
    l_41: '申请退款',
    l_42: '已发货',
    l_43: '已签收',
    l_44: '待发货',
    l_45: '部分发货',
    l_46: '交易完成',
    l_47: '申请售后',
    l_48: '售后中',
    l_49: '售后进度',
    l_50: '退款处理中',
    l_51: '已退款',
    l_52: '售后完成',
    l_53: '再去逛逛',
    l_54: '确认收货',
    l_55: '确定要删除吗，删除之后找不回哟~',
    l_56: '删除成功',
    l_57: '是否确认收货？',
    l_58: '收货成功',
    l_59: '取消收货',
    // ServiceCard
    l_60: '实付：',
    l_61: '待处理',
    l_62: '处理中',
    l_63: '售后拒绝',
    l_64: '已撤销',
    // Tabbar
    l_65: '首页',
    l_66: '分类',
    l_67: '我的',
    // GoodsInfo
    l_68: '预计税费：',
    l_69: '预计税费：商家承担',
    l_70: '服务政策',
    l_71: '正品保障，假一罚十',
    l_72: '消费者告知书',
    l_73: '查看全部',
    l_74: '用户须知 · 跨境答疑 · 价格说明',
    l_75: '商品详情',
    l_76: '加入购物车',
    l_77: '立即购买',
    l_78: '数量：',
    l_79: '商品规格',
    l_80: '商品数量',
    l_81: '消费者告知书',
    l_82: '尊敬的用户：为了您更好地选择境外物品，请您在下单前务必认真详细阅读并完全理解本告知书相关内容，对自身风险作出客观判断，完全同意本告知书内容后再下单。',
    l_83: '2、您购买的所有境外物品适用的品质、健康、安全、卫生、环保、标示等项目标准可能与我国质量安全标准不同，由此可能产生的危害或损失以及法律责任或其他风险，将由您个人承担。',
    l_84: '3、您在本站购买的境外物品可能无中文标签或说明书，请在下单前认真阅读平台提供的物品信息。',
    l_85: '4、建议您对境外物品有一定了解并评估是否适合自己使用后再下单，感谢您的配合。',
    l_86: '已选：',
    l_87: '确定',
    l_88: '请选择商品规格',
    l_89: '添加成功',
    l_90: '1、您通过本网站购买的所有境外物品等同于境外购买。',
    // ConfirmPwd
    l_91: '确认密码',
    l_92: '密码',
    l_93: '请输入您的密码',
    l_94: '重新输入密码',
    l_95: '确定修改',
    l_96: '验证码过期',
    l_97: '修改成功',
    l_98: '密码不同，请重新输入',
    l_99: '密码不能为空',
    l_99_1: '修改',
    l_99_2: '返回',
    // ForgetPwd
    l_100: '忘记密码',
    l_101: '邮箱',
    l_102: '请输入您的邮箱',
    l_103: '验证',
    l_104: '请输入邮箱验证码',
    l_105: 's后重新发',
    l_106: '获取验证码',
    l_107: '邮箱不能为空',
    l_108: '验证码不能为空',
    // Login
    l_109: '请填写邮箱',
    l_110: '请输入你的密码',
    l_111: '请填写密码',
    l_112: '没有账号？',
    l_113: '注册',
    l_114: '登录成功',
    // Register
    l_115: '手机号码',
    l_116: '请输入您的手机号',
    l_117: '发送成功',
    l_118: '注册成功',
    l_119: '请输入相同的密码',
    l_120: '手机号不能为空',
    // MineAddress
    l_121: '姓名',
    l_122: '收货人姓名',
    l_123: '请填写用户名',
    l_124: '电话',
    l_125: '收货人手机号',
    l_126: '请填写手机号',
    l_127: '身份证号',
    l_128: '收货人身份证号',
    l_129: '请填写身份证',
    l_130: '地区',
    l_131: '选择省 / 市 / 区',
    l_132: '请选择省市区',
    l_133: '详细地址',
    l_134: '街道门牌、楼层房间号等信息',
    l_135: '请填写详细地址',
    l_136: '设为默认地址',
    l_137: '确认',
    l_138: '删除地址',
    l_139: '取消',
    l_140: '默认',
    l_141: '编辑',
    l_142: '国内',
    l_143: '海外',
    l_144: '新增收货地址',
    l_145: '中国',
    l_146: '国际',
    l_147: '修改需重新上传身份证',
    l_148: '算了',
    l_149: '去上传',
    l_150: '收货人姓名不能为空',
    l_151: '详细地址不能为空',
    l_152: '请选择地址',
    l_153: '编辑成功',
    l_154: '编辑失败',
    l_155: '添加失败',
    l_156: '确认删除该地址吗？',
    // MineCollect
    l_157: '商品收藏',
    l_158: '品牌收藏',
    l_159: '人付款',
    l_160: '名称：',
    l_161: '描述：',
    l_162: '取消成功',
    // MineConsume
    l_163: '备注：',
    l_164: '暂无消费记录',
    // MineIdCard
    l_165: '请拍摄并上传需要清关人员身份证照片',
    l_166: '身份证信息',
    l_167: '身份证姓名(自动识别)',
    l_168: '身份证',
    l_169: '身份证号码(自动识别)',
    l_170: '关联地址',
    l_171: '选择地址',
    l_172: '身份证上传说明',
    l_173: '根据中国海关规定，为防止变相走私，证明包裹内物品确实为个人自用，个人包裹办理海关入境清关手续需要提交收件人身份证明。',
    l_174: '海关相关规定请参考《中华人民共和国海关对进出境快件监管办法》第二十二条，或致电海关咨询：12360',
    l_175: '拍摄身份证要求',
    l_176: '大陆公民持有的本人有效二代身份证',
    l_177: '拍摄时确保身份证',
    l_178: '边框完整，字体清晰，亮度均匀;',
    l_179: '标准',
    l_180: '边框缺失',
    l_181: '证件模糊',
    l_182: '闪光强烈',
    l_183: '确定上传',
    l_184: '请选择关联地址',
    l_185: '识别成功',
    l_186: '识别失败',
    l_187: '请上传完整的身份证',
    l_188: '上传成功',
    // MineRecharge
    l_189: '如果需要充值账户余额，请联系客服手机号码：',
    // OrderCheck
    l_190: '手动添加收货地址',
    l_191: '实际发货',
    l_192: '发货方式',
    l_193: '请输入收货人身份证号',
    l_194: '订单备注',
    l_195: '选填，添加订单备注',
    l_196: '运费',
    l_197: '实付金额：',
    l_198: '提交订单',
    l_199: '支付成功',
    // OrderInfo
    l_200: '等待买家付款',
    l_201: '逾期未支付，订单自动取消',
    l_202: '感谢您的购买',
    l_203: '等待发货',
    l_204: '等待出库',
    l_205: '售后申请中',
    l_206: '退款成功',
    l_207: '交易已取消',
    l_208: '应付金额',
    l_209: '实付金额',
    l_210: '订单编号：',
    l_211: '复制',
    l_212: '支付方式：',
    l_213: '下单时间：',
    l_214: '温馨提示',
    l_215: '订单一经取消，无法恢复',
    l_216: '订单所用红包，优惠券支持退回',
    l_217: '若您支付未成功，请及时联系客服',
    l_218: '请选择取消订单的原因：',
    l_219: '请选择退款的原因：',
    l_220: '复制成功',
    l_221: '复制失败',
    l_222: '请选择一个原因',
    l_223: '取消失败',
    l_224: '按原支付方式支付',
    l_225: '支付取消',
    l_226: '申请成功',
    l_227: '申请失败',
    // OrderList
    l_228: '你没有相关订单，快去商场下单吧！',
    // Search
    l_229: '搜索',
    l_230: '搜索历史',
    l_231: '热门搜索',
    l_232: '请输入搜索内容',
    l_233: '是否删除全部历史搜索',
    // SearchInfo
    l_234: '综合',
    l_235: '销量',
    l_236: '价格',
    l_237: '没有相关数据哦',
    // ServiceApply
    l_238: '售后类型',
    l_239: '货物状态',
    l_240: '申请原因',
    l_241: '申请说明',
    l_242: '请输入申请说明',
    l_243: '订单金额',
    l_244: '请选择售后商品',
    l_245: '运费金额',
    l_246: '请输入运费金额',
    l_247: '售后金额',
    l_248: '请输入售后金额',
    l_249: '上传凭证',
    l_250: '联系方式',
    l_251: '请输入手机号',
    l_252: '退款方式',
    l_253: '请输入正确的手机号',
    l_254: '手机号码不能为空',
    l_255: '收款账号不能为空',
    l_256: '售后金额不能大于订单金额',
    l_257: '运费金额不能大于订单金额',
    l_258: '扣款金额不能大于订单金额',
    l_259: '上传中',
    l_260: '上传失败',
    l_261: '请勾选需要售后的商品',
    l_262: '请选择售后类型',
    l_263: '请选择货物状态',
    l_264: '请选择申请原因',
    l_265: '提交成功',
    // Service
    l_266: '全部',
    // ServiceInfo
    l_267: '申请已受理，售后专员正在处理中',
    l_268: '退款成功，请耐心等待回款',
    l_269: '尊敬的客户，感谢您前来购物。我们会为您打造优质的购物体验',
    l_270: '退款金额',
    l_271: '商家拒绝您的申请，请及时处理',
    l_272: '尊敬的用户，您的售后已被拒绝。如果您有其他退款诉求请联系客服',
    l_273: '您已主动撤销此次售后申请',
    l_274: '您需要将商品寄回',
    l_275: '地   址：江西 · 南昌 · 红谷滩 · 红角洲学府大道899号',
    l_276: '手   机：13916124423',
    l_277: '收件人：江西美翻科技有限公司',
    l_278: '物流信息已提交，售后专员处理中',
    l_279: '申请已提交，请等待售后专员处理',
    l_280: '申请通过：款项将原路退还至您的账户',
    l_281: '处理时长：专员会在一周之内处理完成',
    l_282: '撤销申请',
    l_283: '售后金额：',
    l_284: '售后类型：',
    l_285: '货物状态：',
    l_286: '申请原因：',
    l_287: '退款编号：',
    l_288: '申请时间：',
    l_289: '确定要撤销吗，撤销之后不能再次哟~',
    l_290: '撤销',
    l_291: '撤销成功',
    l_292: '物流单号不能为空',
    l_293: '请选择物流公司',
    // UpdateInfo
    l_294: '用户名',
    l_295: '昵称',
    l_296: '描述',
    l_297: '原密码',
    l_298: '新密码',
    l_299: '提交',
    l_300: '更改成功',
    l_301: '取消修改',
    l_302: '用户名不能为空',
    l_303: '原密码不能为空',
    // UserInfo
    l_304: '暂无昵称',
    l_305: '主人有点懒，还未添加简介',
    l_306: '昵称',
    l_307: '手机号',
    l_308: '修改用户信息',
    l_309: '退出当前账号',
    l_310: '简介',
    l_311: '退出成功',
    // 补充
    l_312: '查看详情',
    l_313: '取消删除',
    l_314: '刷新成功',
    l_315: '取消撤销',
    l_316: '原價：',
    l_317: 'VIP優惠',
  }
}