import {getArea, detailedArea,orderList} from '@api';
export default {
  getAreaData(context) {
    getArea().then(res => {
      context.commit('setArea', res.data)
    })
  },
  getDetailedArea(context) {
    detailedArea('0000').then(res => {
      context.commit('detaArea', res.data)
    })
  },
  getOrderList(context) {
    orderList().then(res => {
      let arr = res.data.data;
      arr.map((item, index) => {
        var sum = (item.products).reduce((prev, cur) => {
          return (cur.number * cur.sale_price) + prev;
        }, 0);
        item.totalPrice = sum;
      })
      context.commit('setOrder', res.data.data)
    })
  }
}