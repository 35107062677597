const Home = () => import('@page/pages/Home.vue') // 首页

const Cate = () => import('@page/pages/Cate.vue') // 分类

const Cart = () => import('@page/pages/Cart.vue') // 购物车

const Mine = () => import('@page/pages/Mine.vue') // 我的
const UserInfo = () => import('@page/user/UserInfo') // 个人中心
const UpdateInfo = () => import('@page/user/UpdateInfo') // 修改用户信息
const MineAddress = () => import('@page/mine/MineAddress') // 收货地址
const MineIdCard = () => import('@page/mine/MineIdCard') // 上传身份证
const MineConsume = () => import('@page/mine/MineConsume') // 消费账单
const MineRecharge = () => import('@page/mine/MineRecharge') // 账户余额

const Login = () => import('@page/login/Login.vue') // 登录
const ForgetPwd = () => import('@page/login/ForgetPwd.vue') //忘记密码
const Register = () => import('@page/login/Register.vue') //注册
const ConfirmPwd = () => import('@page/login/ConfirmPwd.vue') //修改密码

const Search = () => import('@page/search/Search.vue') // 搜索
const SearchInfo = () => import('@page/search/SearchInfo.vue') // 搜索列表

const GoodsInfo = () => import('@page/goods/GoodsInfo.vue') // 商品详情

const OrderList = () => import('@page/order/OrderList') // 订单列表
const OrderCheck = () => import('@page/order/OrderCheck') // 订单提交
const OrderInfo = () => import('@page/order/OrderInfo') // 订单详情

const Service = () => import('@page/service/Service') // 售后管理
const ServiceInfo = () => import('@page/service/ServiceInfo') // 售后详情
const ServiceApply = () => import('@page/service/ServiceApply') // 售后申请

export default [
  {path: '/', name: 'Home', component: Home, meta: {istabPage: true, keepAlive: true}},

  {path: '/cate', name: 'Cate', component: Cate, meta: {istabPage: true, keepAlive: true}},

  {path: '/cart', name: 'Cart', component: Cart, meta: {istabPage: true}},

  {path: '/mine', name: 'Mine', component: Mine, meta: {istabPage: true}},
  {path: "/user_info", name: "UserInfo", component: UserInfo},
  {path: "/update_info", name: "UpdateInfo", component: UpdateInfo},
  {path: "/address", name: "MineAddress", component: MineAddress},

  {path: '/login', name: 'Login', component: Login},
  {path: '/forget', name: 'ForgetPwd', component: ForgetPwd},
  {path: '/reg_ister', name: 'Register', component: Register},
  {path: '/conpass', name: 'ConfirmPwd', component: ConfirmPwd},
  {path: '/id_card', name: 'MineIdCard', component: MineIdCard},
  {path: "/consume", name: "MineConsume", component: MineConsume},
  {path: "/recharge", name: "MineRecharge", component: MineRecharge},

  {path: '/search', name: 'Search', component: Search, meta: {isUseCache: false, keepAlive: true}},
  {path: '/search_info', name: 'SearchInfo', component: SearchInfo, meta: {isUseCache: false, keepAlive: true}},

  {path: '/goods/:id', name: 'GoodsInfo', component: GoodsInfo},

  {path: "/order_list", name: "OrderList", component: OrderList, meta: {isUseCache: false, keepAlive: true}},
  {path: "/order_check", name: "OrderCheck", component: OrderCheck},
  {path: "/order_info/:id", name: "OrderInfo", component: OrderInfo},

  {path: "/service", name: "Service", component: Service},
  {path: "/service_info/:id", name: "ServiceInfo", component: ServiceInfo},
  {path: "/service_apply", name: "ServiceApply", component: ServiceApply},
]