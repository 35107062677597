<template>
  <van-tabbar v-model="active" inactive-color="#333" active-color="#FB2C37" fixed border route z-index="999">
    <van-tabbar-item v-for="(item, index) in tabInfo" :key="index" :name="item.name" :to="item.route" >
      <span>{{item.text}}</span>
      <img slot="icon" slot-scope="props" :src="props.active ? item.active : item.inactive" />
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      active: 'home',
      tabInfo: [
        {
          text: '首頁',
          name: 'home',
          route: '/',
          inactive: 'https://resource.meifanco.com/20191108/eac2266728f6ded618706c539c43734a.png',
          active: 'https://resource.meifanco.com/20200630/13c36d641e50611471550b7776d66ac8.png'
        },
        {
          text: '分類',
          name: 'cate',
          route: '/cate',
          inactive: 'https://resource.meifanco.com/20191108/3765d8038ad6e279aa9bf1a684e522ba.png',
          active: 'https://resource.meifanco.com/20200630/de554743d3b2542ac2ec378b71684e6a.png'
        },
        {
          text: '購物車',
          name: 'cart',
          route: '/cart',
          inactive: 'https://resource.meifanco.com/20191108/959565f2fb0d9832121253e0503891c8.png',
          active: 'https://resource.meifanco.com/20200630/88999e0e4c850b2bddadd95c268aac5f.png'
        },
        {
          text: '我的',
          name: 'mine',
          route: '/mine',
          inactive: 'https://resource.meifanco.com/20191108/c82d0777a824bbdb3a94eff519526832.png',
          active: 'https://resource.meifanco.com/20200630/0d921fd277df305a0f514173c7fc0632.png'
        }
      ]
    }
  }
}
</script>

<style lang="less" scope>
.van-tabbar {
  img {
    height: 25px!important;
  }
}
</style>