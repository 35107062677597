import store from '@/store'
const language = store.getters.language

export const getStatus = data => {  //判断货物状态
  const {order_status, pay_status, shipping_status} = data;
  if(order_status == 0 && pay_status == 0 && shipping_status == 0) return language.l_25  //待付款
  else if(order_status == 0 && (pay_status == 1 || pay_status == 3) && shipping_status == 0) return language.l_39  //待出库
  else if(order_status == 0 && (pay_status == 1 || pay_status == 3) && shipping_status == 1) return language.l_44  //待发货
  else if(order_status == 0 && (pay_status == 1 || pay_status == 3) && shipping_status == 2) return language.l_42  //已发货
  else if(order_status == 0 && (pay_status == 1 || pay_status == 3) && shipping_status == 3) return language.l_43  //已签收
  else if(order_status == 0 && (pay_status == 1 || pay_status == 3) && shipping_status == 4) return language.l_40  //自提
  else if(order_status == 0 && (pay_status == 1 || pay_status == 3) && shipping_status == 5) return language.l_45  //部分发货
  else if(order_status == 5 && (pay_status == 1 || pay_status == 3)) return language.l_50  //退款处理中
  else if(order_status == 2 && (pay_status == 1 || pay_status == 3)) return language.l_48  //售后中
  else if(order_status == 4 && pay_status == 2) return language.l_51  //已退款
  else if(order_status == 4 && (pay_status == 1 || pay_status == 3)) return language.l_52  //售后完成
  else if(order_status == 1 && (pay_status == 1 || pay_status == 3)) return language.l_46  //交易完成
  else if(order_status == 3 && (pay_status == 0 || pay_status == 2) && shipping_status == 4) return language.l_34  //交易取消
}